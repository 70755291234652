export const environment = {
  production: false
};

// export const baseUrl = 'https://us-central1-cwceng-entitymanagement.cloudfunctions.net/widgets3'
export const baseUrl = 'https://us-central1-altomate-preprod.cloudfunctions.net/widgets3'
export const sgUrl = 'https://us-central1-altomate-preprod.cloudfunctions.net/sgWidget'
export const taxUrl = 'https://us-central1-altomate-preprod.cloudfunctions.net/taxWidget'
export const xeroLink = 'https://preprod.altomate.io'
export const CLOUD_FUNCTION_WIDGETS_URL="https://us-central1-altomate-preprod.cloudfunctions.net/widgets2"
export const TAX_SOURCE_ID="UC_WJD622"
export const XERO_AC_REDIRECT_URI="https://preprod.altomate.io/xero-access"
export const STAGE="preprod"
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyD6gOvYyPL2dEXKzwUdhxLKyB5So6VJvpg",
  authDomain: "altomate-preprod.firebaseapp.com",
  databaseURL: "https://altomate-preprod-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "altomate-preprod",
  storageBucket: "altomate-preprod.appspot.com",
  messagingSenderId: "500692523594",
  appId: "1:500692523594:web:df4491f6ea0529de94e4b3"
};
