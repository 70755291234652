import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as lodash from 'lodash';

@Injectable({
providedIn: 'root'
})
export class DataService {

constructor() { }

private userSource = new BehaviorSubject({});
currentUser = this.userSource.asObservable();

private calendatSource = new BehaviorSubject([]);
calendarEvent = this.calendatSource.asObservable();

private currentWeekkcalendatSource = new BehaviorSubject([]);
currentWeekcalendarEvent = this.currentWeekkcalendatSource.asObservable();

private requestSource = new BehaviorSubject({});
requestDetails = this.requestSource.asObservable();

private applicationSource = new BehaviorSubject([]);
applicationDetails = this.applicationSource.asObservable();

private documentSource = new BehaviorSubject([]);
documentDetails = this.documentSource.asObservable();

private requestDealSource = new BehaviorSubject([]);
requestDeals = this.requestDealSource.asObservable();

private invoicesSource = new BehaviorSubject([]);
invoices = this.invoicesSource.asObservable();

private folderSource = new BehaviorSubject([]);
folders = this.folderSource.asObservable();

// <----- FusionETA - JY (18 August 2023) - Product tour popup- Start ----->
private popupAnimationSource = new BehaviorSubject([]);
popupData = this.popupAnimationSource.asObservable();

updataPopupData(val) {
    this.popupAnimationSource.next(val)
}
// <----- FusionETA - JY (18 August 2023) - Product tour popup - End ----->

// <----- FusionETA - JY (28 August 2023) - Financial Report : Data storage - Start ----->

// Current Year Balance Sheet
private financialReportBalanceSheetCurrentYearSource = new BehaviorSubject([]);
financialReportBalanceSheetCurrentYearData = this.financialReportBalanceSheetCurrentYearSource.asObservable();

// Last Year Balance Sheet
private financialReportBalanceSheetLastYearSource = new BehaviorSubject([]);
financialReportBalanceSheetLastYearData = this.financialReportBalanceSheetLastYearSource.asObservable();

// Last 2 Year Balance Sheet
private financialReportBalanceSheetLast2YearSource = new BehaviorSubject([]);
financialReportBalanceSheetLast2YearData = this.financialReportBalanceSheetLast2YearSource.asObservable();

// Current Year Profit And Loss
private financialReportProfitAndLossCurrentYearSource = new BehaviorSubject([]);
financialReportProfitAndLossCurrentYearData = this.financialReportProfitAndLossCurrentYearSource.asObservable();

// Last Year Profit And Loss
private financialReportProfitAndLossLastYearSource = new BehaviorSubject([]);
financialReportProfitAndLossLastYearData = this.financialReportProfitAndLossLastYearSource.asObservable();

// Last 2 Year Profit And Loss
private financialReportProfitAndLossLast2YearSource = new BehaviorSubject([]);
financialReportProfitAndLossLast2YearData = this.financialReportProfitAndLossLast2YearSource.asObservable();


// Current Year Balance Sheet
updataFinancialReportBalanceSheetCurrentYear(arrayData) {
    this.financialReportBalanceSheetCurrentYearSource.next(arrayData)
}

// Last Year Balance Sheet
updataFinancialReportBalanceSheetLastYear(arrayData) {
    this.financialReportBalanceSheetLastYearSource.next(arrayData)
}

// Last 2 Year Balance Sheet
updataFinancialReportBalanceSheetLast2Year(arrayData) {
    this.financialReportBalanceSheetLast2YearSource.next(arrayData)
}

// Current Year Profit And Loss
updataFinancialReportProfitAndLossCurrentYear(arrayData) {
    this.financialReportProfitAndLossCurrentYearSource.next(arrayData)
}

// Last Year Profit And Loss
updataFinancialReportProfitAndLossLastYear(arrayData) {
    this.financialReportProfitAndLossLastYearSource.next(arrayData)
}

// Last 2 Year Profit And Loss
updataFinancialReportProfitAndLossLast2Year(arrayData) {
    this.financialReportProfitAndLossLast2YearSource.next(arrayData)
}

// <----- FusionETA - JY (14 September 2023) - Financial Report : Data storage - End ----->


updateUser(obj) {
    this.userSource.next(obj)
}

updateRequestDetails(obj) {
    this.requestSource.next(obj)
}

updateFolders(val) {
    this.folderSource.next(val)
}

updateInvoices(val) {
    this.invoicesSource.next(val)
}

updateCalendarEvent(val) {
    this.calendatSource.next(val)
}

updateCurrentWeekCalendarEvent(val){
    this.currentWeekkcalendatSource.next(val)
}

addNewApplication(val) {
    // let previous = this.applicationSource.value
    // previous = previous.splice(previous.findIndex(a => a['id'] == val['id']), 1)
    if(val['id'] != undefined && val['id'] != '' && val['id'] != null){
     this.applicationSource.next(this.applicationSource.value.concat([val]))
    }    
}

removeAllApplication() {
    this.applicationSource.next([])
}

updatedocuments(val) {
    if (val.length > 0) {
     let temp = this.documentSource.value.filter(b => b['dealId'] != val[0]['dealId'])
     this.documentSource.next(lodash.flatten(temp.concat(val)))
    }
}

clearDocument() {
    this.documentSource.next([])
}

removeDocuments(dealId) {
    let temp = this.documentSource.value.filter(b => b['dealId'] != dealId)
    this.documentSource.next(temp)
}

updateRequestDeal(val) {
    if (val.length > 0) {
     // let temp = this.requestDealSource.value.filter(b => b['dealId'] != val[0]['dealId'])
     this.requestDealSource.next(val)
    }
}



}